@import './breaks.scss';
@import './colors.scss';

.packery-container {
	width: 100%;
	margin: 0 auto;
	height: inherit;
}

.grid {
	margin: 0 auto;
	position: relative;
	text-align: center;
}

.grid:after {
	content: '';
	display: block;
	clear: both;
}

.grid-item {
	float: left;
	width: 50px;
	height: 50px;
	position: relative;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
	}
	&:hover {
		filter: brightness(1.5);
	}
}

.grid-item--width2 {
	width: 100px;
}
.grid-item--height2 {
	height: 100px;
}
.grid-item--width3 {
	width: 200px;
}
.grid-item--height3 {
	height: 200px;
}
