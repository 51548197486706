@import './breaks.scss';
@import './colors.scss';

.projects {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	.project {
		position: relative;
		vertical-align: top;
		height: 300px;
		width: 28%;
		margin: 22px;
		background-color: #fff;
		overflow: hidden;
		color: white;
		box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
		border-radius: 3px;
		cursor: pointer;
		@include break(tabletmax) {
			width: 43%;
			margin: 10px;
		}
		@include break(handset) {
			width: 100%;
			margin: 10px;
		}
		.info {
			text-align: left;
			position: absolute;
			background-color: black;
			height: 8%;
			vertical-align: top;
			background-color: rgba(0, 0, 0, 0.8);
			color: white;
			transition: opacity 0.5s, width 0.5s, height 0.2s, background-color 0.5s, color 0.5s, transform 0.5s;
			overflow: hidden;
			padding: 10px;
			z-index: 2;
			.title {
				display: inline-block;
				padding: 0;
				margin: 0;
			}
			.tag-icons {
				float: right;
				.tag-icon {
					width: 20px;
				}
			}
			.description,
			.action {
				font-size: 14px;
			}
			.action {
				font-weight: 600;
			}
			.github {
				position: absolute;
				margin: 0 auto;
				left: 0;
				right: 0;
				text-align: center;
				bottom: 35px;
				transition: opacity 0.1s ease-out;
				opacity: 0;
				p {
					margin: 0;
					font-size: 12px;
				}
				.fa-github {
					font-size: 20px;
				}
			}
		}
		.project-thumbnail {
			max-width: 375px;
		}
		&:hover {
			.project-thumbnail {
				filter: blur(5px);
				z-index: 1;
			}
			.info {
				height: 100%;
				background-color: rgba(0, 0, 0, 0.85);
				.title {
					text-shadow: 0px 1px 3px rgb(229 220 255);
				}
				.github {
					transition: opacity 1s ease-in;
					opacity: 1;
				}
			}
		}
	}
}

.codepen {
	margin-bottom: 20px;
}
