@import './breaks.scss';
@import './colors.scss';

.footer {
	padding: 15px;
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	.muted {
		--bs-text-opacity: 1;
		color: #6c757d !important;
	}
	p {
		font-size: 18px;
		&.small {
			font-size: 15px;
		}
	}
	.react {
		p {
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			margin-top: 2px;
			margin-right: 10px;
		}
		img {
			width: 25px;
			display: inline;
		}
	}
	.footer-logos {
		img {
			width: 40px;
			margin-right: 10px;
			transition: 0.3s ease-in-out;
			cursor: pointer;
			&:hover {
				filter: brightness(120%);
			}
		}
	}
}
