/* Main theme colors */
$hoverpurple: #bf7ed4;
$purple: #8d5da2;

$darkpurple: #1c002a;
$medpurple: #341640;
$lightpurple: #874c96;
$lightgold: #d1ce35;
$medgold: #E4B90F;
$gold: #ba791d;
$darkgold: #905400;
$newpurple: #8153C3;

$red: #f3344d;
$green: #319C67;

$light-gray: #dddddd;
$medium-gray: #696571;
$dark-gray: #37353b;
$darker-gray: #2a282b;
$border-gray: #eaeaea;
$form-gray: #f2f2f2;
$white: #ffffff;

$footer-text: #696571;
